import endpoints from './endpoints'

export default class PlayerSDK {
  constructor (http) {
    this.http = http
  }

  getProfile (id, memberId, full) {
    const base = endpoints.profile.base(id || memberId)
    const q = []
    if (!id) q.push('member=true')
    if (full) q.push('full=true')
    var url = `${base}${q.length ? '?' + q.join('&') : ''}`
    return this.http.get(url)
  }

  getMemberships (id) {
    return this.http.get(`/playerprofile/${id}/memberships`)
  }

  getBids (id) {
    return this.http.get(`/playerprofile/${id}/bids`)
  }

  getRaw (ids) {
    return this.http.get(`/playerprofile/raw?ids=${ids}`)
  }

  merge (dto, query) {
    return this.http.post(`/playerprofile/merge${query}`, dto)
  }

  post (profile, image) {
    const dto = image || new FormData()
    dto.append('profile', JSON.stringify(profile))
    return this.http.post(endpoints.profile.base(profile.id), dto)
  }

  patch (profile, raw) {
    return this.http.patch(`/playerprofile/${profile.id}${raw ? '/raw' : ''}`, profile)
  }

  patchNumber (profile) {
    return this.http.patch(`${endpoints.profile.base(profile.id)}/number`, profile)
  }

  getRankings (sbid) {
    return this.http.get(endpoints.rankings.byId(sbid))
  }

  getNewRankings (id, search) {
    var s = search ? `?search=${search}` : ''
    return this.http.get(`ranking/new/${id}${s}`)
  }

  getGPRankings (id, search) {
    return this.http.post(`ranking/new/${id}`, search)
  }

  search (searchTerm) {
    return this.http.get(endpoints.profile.search(searchTerm))
  }

  lookup (dto) {
    return this.http.post('/players/lookup', dto)
  }

  partners (dto) {
    return this.http.post('/players/partners', dto)
  }

  membershipCheck (id) {
    return this.http.post('/players/membership-check', { ids: [id] })
  }

  finishes (playerProfileId) {
    return this.http.get(endpoints.profile.finishes(playerProfileId))
  }

  permit (id, memberId) {
    const url = `${endpoints.profile.base(id || memberId)}/permit${!id ? '?member=true' : ''}`
    return this.http.get(url)
  }

  hasSeasonPass (passId, playerProfileId) {
    return this.http.get(`/playerprofile/seasonpass/${passId}/${playerProfileId}`)
  }

  getContacts (playerProfileId) {
    return this.http.get(`/playerprofile/${playerProfileId}/contact`)
  }

  getSocial (ids) {
    return this.http.post('players/social', ids)
  }

  images = {
    getAll: (playerProfileId) => {
      return this.http.get(`/images/player/${playerProfileId}`)
    },
    delete: (playerProfileId, imageId) => {
      return this.http.delete(`/images/player/${playerProfileId}/${imageId}`)
    },
    post: (playerProfileId, images) => {
      return this.http.post(`/images/player/${playerProfileId}`, images)
    },
    makeProfile: (playerProfileId, imageId) => {
      return this.http.post(`/images/profile?playerId=${playerProfileId}&imageId=${imageId}`)
    }
  }

  users = {
    get: (playerProfileId) => {
      return this.http.get(`/playerprofile/${playerProfileId}/users`)
    },
    delete: (playerProfileId, uppId) => {
      return this.http.delete(`/playerprofile/${playerProfileId}/users/${uppId}`)
    },
    post: (playerProfileId, images) => {
      return this.http.post(`/images/player/${playerProfileId}`, images)
    },
    claimCheck: (playerProfileId) => {
      return this.http.get(`/playerprofile/${playerProfileId}/claimCheck`)
    },
    makeClaim: (dto) => {
      return this.http.post('/playerprofile/claim', dto)
    },
    playerProfileCheck: () => {
      return this.http.get('/me/playerProfileCheck')
    },
    playerProfileClean: (playerId) => {
      return this.http.get(`/me/playerProfileClean?playerId=${playerId}`)
    }
  }

  videos = {
    getAll: (playerProfileId) => {
      return this.http.get(`/images/player/${playerProfileId}/video`)
    },
    getDetails: (url) => {
      return this.http.post('/video/data', { url: url })
    },
    post: (playerProfileId, videoDetailsId) => {
      return this.http.post(`/video/player/${playerProfileId}?videoDetailsId=${videoDetailsId}`)
    },
    yt: {
      post: (playerProfileId, url) => {
        const dto = {
          url: url,
          playerProfileId: playerProfileId
        }
        console.log(dto)
        return this.http.post(`/images/player/${playerProfileId}/youtube`, dto)
      }
    }
  }

  verification = {
    post: (playerProfileId, dto) => {
      return this.http.post(`/playerprofile/${playerProfileId}/verifications`, dto)
    }
  }

  streams = {
    get: (playerProfileId) => {
      return this.http.get(`/LiveStream/player/${playerProfileId}`)
    },
    setShow: (playerProfileId, streamId, show) => {
      return this.http.get(`/LiveStream/player/toggle/onprofile?pId=${playerProfileId}&sId=${streamId}&show=${show}`)
    }
  }

  streams (playerProfileId) {
    return this.http.get(`/LiveStream/player/${playerProfileId}`)
  }
}
