<template>
  <v-container fluid class="pt-0">
      <v-row dense align="end">
        <!-- Undo Manual changes -->
        <v-dialog
          v-if="editing && isManual"
          v-model="undoConfirm"
          persistent
          max-width="500px"
          transition="dialog-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="color3" small text class="ma-0 pa-0">Undo Manual changes</v-btn>
          </template>
          <v-card>
            <v-toolbar color="color1 color1Text--text">
              <v-toolbar-title>Are you sure?</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <h3>Are you sure you want to delete your changes?</h3>
              You will not be able to undo this.
            </v-card-text>
            <v-card-actions>
              <v-row dense justify="center">
                <v-btn color="success" class="mr-2" @click.stop="undo" :loading="loading">Yes</v-btn>
                <v-btn color="error" @click.stop="undoConfirm=false" :disabled="loading">No</v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <v-btn color="color3" text icon @click.stop="sort()" v-if="user && user.vbl">
          <v-icon>fal fa-sort</v-icon>
        </v-btn>
        <v-btn color="color3" text icon @click.stop="copy()">
          <v-icon>fal fa-copy</v-icon>
        </v-btn>
        <v-snackbar
          v-model="copied"
          vertical
          color="success white--text"
          :timeout="3000"
        >
          Advancing teams have been copied to the clipboard
          <v-btn text color="white" @click.native="copied = false">Close</v-btn>
        </v-snackbar>
        <!-- Help/Explanation Dialog -->
        <v-dialog
          v-model="explain"
          scrollable
          max-width="500px"
          transition="dialog-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn color="color3 color3Text--text" fab small v-on="on">
              <v-icon small>fas fa-question</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-toolbar dense color="color1 color1Text--text">
              <v-toolbar-title>Team Selection</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="color1Text" icon text @click.stop="explain = false">
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <div class="caption color1--text">Teams from:</div>
                  <div class="subheading pl-1">{{division.name}} {{fromRound ? fromRound.name : ''}}</div>
                </v-col>
                <v-col cols="12">
                  <div class="caption color1--text">Teams advancing:</div>
                  <div class="subheading pl-1">{{advancing}}</div>
                </v-col>
                <v-col cols="12">
                  <div class="caption color1--text">Seeding:</div>
                  <template v-if="seedStyle">
                    <div class="subheading pl-1">{{seedStyle.display}}</div>
                    <p v-for="(desc, i) in seedStyle.description" :key="i" class="pl-1">{{desc}}</p>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row dense v-if="user && user.vbl && missingSeeds.length > 0">
        <v-col cols="12" class="error--text">
          Missing Seeds: {{missingSeeds | formatArray}}
        </v-col>
      </v-row>
      <sortable-teams
        :teams="teams"
        :showMap="true"
        :repField="repField"
        :mapLabel="mapLabel"
        :editing="editing"
        :bracket="bracket"
        @dirty-change="onDirtyChange"
        @cancel="cancel"
        ref="teamsTable"
      ></sortable-teams>

      <!-- BUTTONS -->
      <template v-if="admin && view.admin && !division.complete && !round.locked">
        <v-fab-transition>
          <v-btn
            v-if="dirty"
            fab
            :small="$vuetify.breakpoint.xs"
            fixed
            bottom
            right
            color="color3 color3Text--text"
            :style="`margin-right: ${60}px`"
            @click.stop="save"
            :loading="loading"
          >
            <v-icon :small="$vuetify.breakpoint.xs">fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
        <!-- EDIT & CANCEL -->
        <v-fab-transition>
          <v-btn
            :color="activeFab.color"
            :key="activeFab.icon"
            fab
            :small="$vuetify.breakpoint.xs"
            fixed
            bottom
            right
            @click.stop="activeFab.click"
          >
            <v-icon :small="$vuetify.breakpoint.xs">{{activeFab.icon}}</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <v-row v-if="user && user.vbl">
        <v-col cols="12" class="xsmall">
          Selector Id: {{selector.id}}
        </v-col>
      </v-row>

  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import SeedingStyles from '@/classes/SeedingStyles'
import RouteMixin from '@/Mixins/RouteMixin'
const SortableTeams = () => import('./SortableTeamList.vue')

export default {
  mixins: [RouteMixin],
  props: ['summary', 'selector', 'bracket', 'division'],
  data () {
    return {
      sorting: false,
      explain: false,
      editing: false,
      loading: false,
      dirty: false,
      undoConfirm: false,
      copied: false
    }
  },
  computed: {
    ...mapGetters(['getTeam', 'getRound', 'admin', 'view', 'user']),
    missingSeeds () {
      const m = []
      if (this.bracket) {
        for (var i = 1; i <= this.bracket.teamCount; i++) {
          const x = this.teams.find(f => f.seed === i)
          if (!x) m.push(i)
        }
      }
      return m
    },
    round () {
      return this.getRound(this.dayId)
    },
    teams () {
      return this.summary.teams
    },
    isManual () {
      return this.summary.summaries.filter(f => f.isManual).length
    },
    activeFab () {
      return this.editing ? {
        color: 'red white--text',
        icon: 'fas fa-times',
        click: this.cancel
      } : {
        color: 'color3Text color3--text',
        icon: 'fas fa-pencil-alt',
        click: this.edit
      }
    },
    repField () {
      return this.bracket && this.bracket.repField
    },
    mapLabel () {
      return this.fromRound && this.fromRound.poolPlay ? 'Pool Result' : ''
    },
    seedStyle () {
      return this.selector && this.selector.seedStyle && SeedingStyles.find(f => f.style === this.selector.seedStyle)
    },
    advancing () {
      const t = this.selector.type
      let result = ''
      if (t === 'TOP_N_EACH') {
        result = `Top ${this.selector.n} teams from each pool`
      } else if (t === 'ALL') {
        result = 'All'
      }
      return result
    },
    fromRound () {
      return this.selector && this.selector.fromRoundId ? this.getRound(this.selector.fromRoundId) : null
    },
    results () {
      return this.selector.results
    },
    teamsIn () {
      return this.teams && this.teams.filter(f => f.in)
    },
    teamsOut () {
      return this.teams && this.teams.filter(f => !f.in)
    }
  },
  methods: {
    sort () {
      this.$refs.teamsTable.sort()
    },
    copy () {
      var table = document.createElement('table')
      let tr = document.createElement('tr')
      let td = document.createElement('td')
      td.innerHTML = 'Team'
      tr.appendChild(td)
      td = document.createElement('td')
      td.innerHTML = 'Seed'
      tr.appendChild(td)
      td = document.createElement('td')
      td.innerHTML = 'Pool'
      tr.appendChild(td)
      table.appendChild(tr)

      this.teamsIn.forEach(t => {
        tr = document.createElement('tr')
        td = document.createElement('td')
        td.innerHTML = t.name
        tr.appendChild(td)
        td = document.createElement('td')
        td.innerHTML = t.seed
        tr.appendChild(td)
        td = document.createElement('td')
        const pool = this.fromRound.pools.find(f => f.id === t.fromPoolId)
        td.innerHTML = pool ? pool.name : ''
        tr.appendChild(td)
        table.appendChild(tr)
      })
      var textArea = document.createElement('textarea')
      textArea.style.position = 'fixed'
      textArea.style.top = 0
      textArea.style.left = 0
      textArea.style.width = '2em'
      textArea.style.height = '2em'
      textArea.style.padding = 0
      textArea.style.border = 'none'
      textArea.style.outline = 'none'
      textArea.style.boxShadow = 'none'
      textArea.style.background = 'transparent'

      textArea.value = table.outerHTML
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        this.copied = true
        console.log('Copying text command was ' + msg)
      } catch (err) {
        console.log('Oops, unable to copy')
      }

      document.body.removeChild(textArea)
    },
    edit () {
      this.editing = true
    },
    cancel () {
      this.editing = false
      this.undoConfirm = false
      this.dirty = false
    },
    onDirtyChange (val) {
      this.dirty = val
    },
    save (undo) {
      this.loading = true
      const dto = undo === true ? [] : this.$refs.teamsTable.dto
      this.$VBL.post.teams(dto, this.roundId)
        .then(r => {
          this.$emit('saved-manual')
          this.cancel()
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    undo () {
      this.save(true)
    }
  },
  components: {
    SortableTeams
  }
}
</script>

<style scoped>
label {
  font-size: 16px;
  transform: scale(0.75);
}
</style>
