<template>
  <v-container fluid class="pt-0">
    <v-row dense>
      <BidStream></BidStream>
    </v-row>
    <v-row dense>
      <v-col class="text-right" cols="12" v-if="rankings.length > 1">
        <router-link :to="{name: 'rankings-home'}" class="grey--text clickable">Looking for other rankings?</router-link>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text" dark>
            <v-toolbar-title tabs>{{pointSystem ? pointSystem.name : ''}} Rankings</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              :slot="$vuetify.breakpoint.smAndDown ? 'extension' : null"
              :class="{ 'mb-3': $vuetify.breakpoint.smAndDown }"
              color="color1Text--text"
              append-icon="fas fa-search"
              label="Search"
              hide-details
              v-model="search"
              solo-inverted
              :light="!color1IsDark"
              clearable
            ></v-text-field>
          </v-toolbar>
          <v-expand-transition>
            <v-card-text v-if="jProps && jProps.preamble" v-html="jProps.preamble">
            </v-card-text>
          </v-expand-transition>
          <v-card-text class="color1">
            <v-row>
              <v-col cols="6" sm="3">
                <v-select
                  color="color3"
                  item-color="color3"
                  label="Season"
                  hide-details
                  :items="seasons"
                  v-model="season"
                  dark filled
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3">
                <v-select
                  color="color3"
                  item-color="color3"
                  label="Series"
                  hide-details
                  :items="gpLists"
                  item-text="title"
                  item-value="value"
                  v-model="series"
                  dark filled
                ></v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                  color="color3"
                  item-color="color3"
                  label="Division"
                  hide-details
                  :items="selectedSeries.divisions"
                  item-text="title"
                  item-value="value"
                  v-model="division"
                  dark filled
                ></v-select>
              </v-col>
              <v-slide-x-reverse-transition>
                <v-col cols="12" sm="3" v-if="selectedSeries.zonal">
                  <v-select
                    color="color3"
                    item-color="color3"
                    label="Zone"
                    hide-details
                    :items="zones"
                    item-text="title"
                    item-value="value"
                    v-model="zone"
                    dark filled
                  ></v-select>
                </v-col>
              </v-slide-x-reverse-transition>
            </v-row>
          </v-card-text>

          <v-expand-transition>
            <v-row dense  v-if="ads">
              <v-col cols="12" class="d-flex justify-center">
                <div class="mr-2" style="height: 75px; width: 395px;" v-for="ad in ads" :key="ad.id">
                  <ad-card :ad="ad" :flat="true"></ad-card>
                </div>
              </v-col>
            </v-row>
          </v-expand-transition>
            <ranking-list
              :id="id"
              :players="displayPlayers"
              :searchTerm="search"
              :loading="loading"
              :filter="filterItems"
              :adults="adults"
              :juniors="juniors"
              :preCalc="preCalc"
            ></ranking-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="user && user.vbl">
      <v-btn color="success" @click.stop="onDownloadClick">download</v-btn>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      transition="dialog-transition"
    >
    <v-card>
      <v-toolbar color="color1 color1Text--text" class="title">
        <v-toolbar-title>
          <v-icon color="color1Text" class="mr-3">fas fa-construction</v-icon>Under Construction
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-3">
        <v-alert type="warning" :value="true" prominent class="ma-2">
          We are working on getting all the results imported please be patient
        </v-alert>
      </v-card-text>
    </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RankingList from '@/components/RankingsList.vue'
import { mapGetters } from 'vuex'
import Papa from 'papaparse'
import AdCard from '@/components/Ads/AdCard.vue'
import { season } from '@/classes/ProfileHelpers.js'

export default {
  props: ['id'],
  data () {
    return {
      loading: false,
      players: null,
      search: '',
      activeTab: 'girls',
      filter: false,
      filterItems: [],
      dialog: false,
      adults: true,
      juniors: true,
      ages: ['10U', '11U', '12U', '13U', '14U', '15U', '16U', '17U', '18U'],
      ageChoices: [],
      allDivisions: ['18U', '16U', '14U', '12U', '10U', 'B', 'BB', 'A', 'AA', 'AAA', 'Unrated', 'Open', '15U', '17U', '13U'],
      ads: [],
      timeoutId: null,
      preCalc: true,
      jProps: null,
      states: [],
      gpLists: [
        {
          title: 'Men\'s Sand',
          value: 1,
          props: { systemId: this.id, surfaceId: 1, ageTypeId: 2, coed: false },
          zonal: true,
          divisions: [
            'Open',
            'AA',
            'A',
            'BB',
            'B'
          ],
          gender: 'm'
        },
        {
          title: 'Women\'s Sand',
          value: 2,
          props: { systemId: this.id, surfaceId: 1, ageTypeId: 2, coed: false },
          zonal: true,
          divisions: [
            'Open',
            'AA',
            'A',
            'BB',
            'B'
          ],
          gender: 'f'
        },
        {
          title: 'Coed Sand',
          value: 3,
          props: { systemId: this.id, surfaceId: 1, ageTypeId: 2, coed: true },
          zonal: true,
          divisions: [
            'Open',
            'AA',
            'A',
            'BB',
            'B'
          ],
          gender: 'c'
        },
        {
          title: 'Men\'s Grass',
          value: 4,
          props: { systemId: this.id, surfaceId: 2, ageTypeId: 2, coed: false },
          zonal: true,
          divisions: [
            'Open',
            'AA',
            'A',
            'BB',
            'B'
          ],
          gender: 'm'
        },
        {
          title: 'Women\'s Grass',
          value: 5,
          props: { systemId: this.id, surfaceId: 2, ageTypeId: 2, coed: false },
          zonal: true,
          divisions: [
            'Open',
            'AA',
            'A',
            'BB',
            'B'
          ],
          gender: 'f'
        },
        {
          title: 'Coed Grass',
          value: 6,
          props: { systemId: this.id, surfaceId: 2, ageTypeId: 2, coed: true },
          zonal: true,
          divisions: [
            'Open',
            'AA',
            'A',
            'BB',
            'B'
          ],
          gender: 'c'
        },
        {
          title: 'Girls',
          value: 7,
          props: { systemId: this.id, surfaceId: 1, ageTypeId: 1, coed: false },
          zonal: true,
          divisions: [
            '18U',
            '16U',
            '14U',
            '12U'
          ],
          gender: 'f'
        },
        {
          title: 'Boys',
          value: 8,
          props: { systemId: this.id, surfaceId: 1, ageTypeId: 1, coed: false },
          zonal: true,
          divisions: [
            '18U',
            '16U',
            '14U',
            '12U'
          ],
          gender: 'm'
        }
      ],
      zones: [
        { title: 'All', value: false },
        { title: 'Zone 1 (West)', value: 'Zone 1' },
        { title: 'Zone 2 (Central)', value: 'Zone 2' },
        { title: 'Zone 3 (Midwest)', value: 'Zone 3' },
        { title: 'Zone 4 (Southeast)', value: 'Zone 4' },
        { title: 'Zone 5 (Northeast)', value: 'Zone 5' }
      ],
      series: 1,
      zone: false,
      seasons: [2024, 2025],
      season: 2025,
      division: 'Open',
      gender: 'm'
    }
  },
  computed: {
    ...mapGetters(['theme', 'color1IsDark', 'rankings', 'user', 'liveUpdates', 'liveUpdateStatus', 'subdomain']),
    downloadData () {
      const t = this.$refs[`${this.activeTab}List`]
      if (t && t.rows) {
        return t.rows.map(m => {
          return {
            rank: m.rank,
            name: m.name,
            emails: m.emails,
            ageDivision: m.ageDivision
          }
        })
      }
      return []
    },
    pointSystem () {
      console.log(this.rankings)
      const x = this.rankings.find(f => f.id === +this.id)
      console.log(x)
      return x
    },
    hasAdults () {
      return this.players && this.players.filter(p => p.ageDivision === 'Adult').length > 0
    },
    girls () {
      return (this.players && this.players.filter((p) => {
        return !p.isMale && p.ageDivision.endsWith(` ${this.division}`) && (!this.zone || this.selectedStates.includes(p.cityState.slice(-2)))
      })) || []
    },
    boys () {
      return (this.players && this.players.filter((p) => {
        return p.isMale && p.ageDivision.endsWith(` ${this.division}`) && (!this.zone || this.selectedStates.includes(p.cityState.slice(-2)))
      })) || []
    },
    coed () {
      return (this.players && this.players.filter((p) => {
        return p.ageDivision.endsWith(` ${this.division}`) && (!this.zone || this.selectedStates.includes(p.cityState.slice(-2)))
      })) || []
    },
    displayPlayers () {
      return this.gender === 'm' ? this.boys : this.gender === 'f' ? this.girls : this.coed
    },
    selectedSeries () {
      return this.gpLists.find(f => f.value === this.series)
    },
    selectedStates () {
      const s = this.zone ? this.states.filter(f => f.zone === this.zone) : []
      const abb = s.map(m => m.value)
      return abb
    }
  },
  methods: {
    getFilters () {
      const s = season()
      const gy = ![3, 19].includes(this.id)
      let i = 18
      this.ageChoices = []
      for (i; i >= 10; i--) {
        const y = s + (18 - i)
        this.ageChoices.push({
          title: gy ? `Class of ${y} (${i}U)` : `${i}U`,
          value: gy ? `${y}` : `${i}U`
        })
      }
    },
    getAds () {
      const adsFor = this.pointSystem.sb ? this.pointSystem.name.toLowerCase() : this.subdomain
      this.$http.get(`/ads?username=${adsFor}`)
        .then(r => {
          this.ads = r.data.map(m => JSON.parse(m)).filter(f => f.locations && f.locations.includes('rankings'))
        })
    },
    onSeriesChange (n, o) {
      const newS = this.gpLists.find(f => f.value === n)
      const oldS = this.gpLists.find(f => f.value === o)

      if (JSON.stringify(newS.props) !== JSON.stringify(oldS.props)) {
        this.getPlayers()
      }
      this.gender = newS.gender
    },
    getPlayers () {
      this.loading = true
      this.players = []
      if (!this.division || !this.selectedSeries.divisions.includes(this.division)) {
        this.division = this.selectedSeries.divisions[0]
      }
      const props = JSON.parse(JSON.stringify(this.selectedSeries.props))
      props.season = this.season
      this.$VBL.player.getGPRankings(this.id, props)
        .then(r => {
          this.preCalc = r.data.preCalc
          this.jProps = r.data.jsonProps
          this.players = r.data.players
          console.log(this.players)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => { this.loading = false })
    },
    onDownloadClick () {
      var fileName = `${this.pointSystem ? this.pointSystem.name : ''}_Rankings_.csv`
      var data = this.downloadData
      this.createFileForDownload(data, fileName)
    },
    createFileForDownload (data, name) {
      var opt = { header: !this.avpaFormat }
      var csv = Papa.unparse(data, opt)
      var blob = new Blob([csv])
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    getStates () {
      this.$http.get(`${window.location.origin}/static/json/states.json`)
        .then(r => {
          this.states = r.data.map(m => {
            return {
              title: m.name,
              value: m.abbreviation,
              zone: m.avpZone
            }
          })
        })
    }
  },
  watch: {
    activeTab () {
      this.search = ''
    },
    series: 'onSeriesChange',
    season: 'getPlayers'
  },
  components: {
    RankingList,
    AdCard
  },
  created () {
    this.getStates()
  },
  mounted () {
    this.getPlayers()
    this.getAds()
  }
}
</script>

<style>

</style>
