/* eslint-disable no-prototype-builtins */
<template>
  <v-container fluid class="pa-0" :id="`${$v.$dirty}`">
    <v-row dense v-if="player.title" class="mt-2">
      <v-col cols="12">
        <h3>{{player.title}} {{player.subtitle}}</h3>
      </v-col>
    </v-row>
    <!-- Fields -->
    <!-- Name -->
    <v-row dense v-if="_fields.includes('name') || _fields.includes('*')">
      <v-col cols="12" sm="6" :md="_fields.includes('gender') && !embed ? 4 : 6">
        <v-text-field
          name="firstName"
          :label="`${player.title || ''} First Name*`"
          v-model="player.firstName"
          @blur="$v.player.firstName.$touch()"
          :error-messages="errors.firstName"
          color="color3"
          :disabled="disable"
          :hide-details="disable"
          :placeholder="onFile ? 'On File' : null"
          :solo="solo && !player.firstName && !onFile"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" :md="_fields.includes('gender') && !embed ? 4 : 6">
        <v-text-field
          name="lastName"
          :label="`${player.title || ''} Last Name*`"
          v-model="player.lastName"
          :error-messages="errors.lastName"
          @blur="$v.player.lastName.$touch(); $emit('blur')"
          color="color3"
          :disabled="disable"
          :hide-details="disable"
          :placeholder="onFile ? 'On File' : null"
          :solo="solo && !player.lastName && !onFile"
        ></v-text-field>
      </v-col>
      <v-col cols="12" :sm="!embed ? 6 : 12" :md="!embed ? 4 : 12" v-if="_fields.includes('gender')" >
        <v-radio-group
          v-model="player.male"
          row
          :disabled="disable"
        >
          <v-radio color="success" label="Female" :value="false"></v-radio>
          <v-radio color="success" label="Male" :value="true"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <!-- Country -->
    <v-row dense v-if="allowInternational && (_fields.includes('fullAddress') || _fields.includes('*'))">
      <v-col cols="12" sm="6">
        <v-autocomplete
          label="Country*"
          :items="countries"
          item-text="name"
          item-value="name"
          v-model="player.country"
          :hint="player.country ? '' : 'Please select your country'"
          persistent-hint
          color="color3"
          item-color="color3"
          autocomplete="off-yo"
          type="search"
          @blur="$v.player.country.$touch()"
          :error-messages="errors.country"
          :disabled="disable"
          :hide-details="disable"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <!-- Phone/Email -->
    <v-row dense v-if="_fields.includes('phone') || _fields.includes('email') || _fields.includes('*')">
      <v-col cols="12" :sm="embed ? 12 : 6" v-if="_fields.includes('phone') || _fields.includes('*')">
        <v-text-field
          v-if="!international"
          name="phone"
          key="usPhone"
          :label="$v.player.phone.hasOwnProperty('required') ? `${player.title || ''} Mobile Phone*` : `${player.title || ''} Mobile Phone`"
          v-model="player.phone"
          type="tel"
          v-mask="'(###) ###-####'"
          @blur="$v.player.phone.$touch(); $emit('blur')"
          color="color3"
          :disabled="disable"
          :hide-details="disable"
          :error-messages="errors.phone"
          :placeholder="onFile ? 'On File' : null"
          :solo="solo && !player.phone && !onFile"
        ></v-text-field>
        <v-text-field
          v-else
          name="phone"
          key="intPhone"
          :label="$v.player.phone.hasOwnProperty('required') ? `${player.title || ''} Mobile Phone*` : `${player.title || ''} Mobile Phone`"
          v-model="player.phone"
          type="tel"
          @blur="$v.player.phone.$touch(); $emit('blur')"
          color="color3"
          :disabled="disable"
          :hide-details="disable"
          :error-messages="errors.phone"
          :placeholder="onFile ? 'On File' : null"
          :solo="solo && !player.phone && !onFile"
        ></v-text-field>
      </v-col>
      <v-col cols="12" :sm="embed ? 12 : 6" v-if="_fields.includes('email') || _fields.includes('*')">
        <v-text-field
          name="email"
          :label="$v.player.email.hasOwnProperty('required') ? `${player.title || ''} Email*` : `${player.title || ''} Email`"
          v-model="player.email"
          :rules="$v.player.email.hasOwnProperty('required') && $v.player.email.$dirty ? [
            () => $v.player.email.required || 'An email is required',
            () => $v.player.email.email || 'A valid email is required'
          ] : []"
          @blur="$v.player.email.$touch(); $emit('blur')"
          color="color3"
          :disabled="disable"
          :hide-details="disable"
          :error-messages="errors.email"
          :placeholder="onFile ? 'On File' : null"
          :solo="solo && !player.email && !onFile"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- Address -->
    <template v-if="_fields.includes('fullAddress') || _fields.includes('*')">
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            name="address1"
            :label="$v.player.address.hasOwnProperty('required') ? 'Address line 1*' : 'Address line 1'"
            v-model="player.address"
            :error-messages="errors.address"
            @blur="$v.player.address.$touch()"
            color="color3"
            :disabled="disable"
            :hide-details="disable"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            name="address2"
            label="Address line 2"
            v-model="player.address2"
            color="color3"
            :disabled="disable"
            :hide-details="disable"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <!-- City State Zip -->
    <v-row dense v-if="_fields.includes('fullAddress') || _fields.includes('cityState') || _fields.includes('zip') || _fields.includes('*')">
      <!-- City -->
      <v-col cols="12" sm="6">
        <v-text-field
          name="city"
          :label="$v.player.city.hasOwnProperty('required') ? 'City*' : 'City'"
          v-model="player.city"
          @blur="$v.player.city.$touch()"
          :error-messages="errors.city"
          color="color3"
          :disabled="disable"
          :hide-details="disable"
        ></v-text-field>
      </v-col>
      <!-- Domestic -->
      <template v-if="!international">
        <!-- State -->
        <v-col :class="_fields.includes('fullAddress') ? 'xs6 sm3' : 'xs12 sm6'">
          <v-autocomplete
            :label="$v.player.state.hasOwnProperty('required') ? 'State*' : 'State'"
            :items="states"
            item-text="abbreviation"
            item-value="abbreviation"
            v-model="player.state"
            @blur="$v.player.state.$touch()"
            :error-messages="errors.state"
            color="color3"
            item-color="color3"
            autocomplete="off-yo"
            type="search"
            :disabled="disable"
            :hide-details="disable"
          ></v-autocomplete>
        </v-col>
        <!-- Zip -->
        <v-col cols="6" sm="3" v-if="_fields.includes('fullAddress') || _fields.includes('zip') || _fields.includes('*')">
          <v-text-field
            name="zip"
            :label="$v.player.zip.hasOwnProperty('required') ? 'Zip*' : 'Zip'"
            v-model="player.zip"
            v-mask="'#####'"
            @blur="$v.player.zip.$touch()"
            :error-messages="errors.zip"
            color="color3"
            :disabled="disable"
            :hide-details="disable"
          ></v-text-field>
        </v-col>
      </template>
      <!-- International -->
      <template v-else>
        <v-col cols="12" sm="6">
          <v-text-field
            :label="$v.player.state.hasOwnProperty('required') ? 'State/Province/Region*' : 'State/Province/Region'"
            v-model="player.state"
            @blur="$v.player.state.$touch()"
            :error-messages="errors.state"
            color="color3"
            :disabled="disable"
            :hide-details="disable"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" v-if="_fields.includes('fullAddress') || _fields.includes('*')">
          <v-text-field
            name="zip"
            :label="$v.player.zip.hasOwnProperty('required') ? 'ZIP/Postal Code*' : 'ZIP/Postal Code'"
            v-model="player.zip"
            @blur="$v.player.zip.$touch()"
            :error-messages="errors.zip"
            color="color3"
            :disabled="disable"
            :hide-details="disable"
          ></v-text-field>
        </v-col>
      </template>
    </v-row>
    <!-- DOB & Grad Year -->
    <v-row dense v-if="!!_fields.find(f => ['dob','shirtSize','height','facebook','instagram','twitter','snapchat','tiktok','social','*'].includes(f))">
      <!-- DOB -->
      <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4" v-if="_fields.includes('dob') || _fields.includes('*')">
        <v-dialog
          ref="dobPicker"
          v-model="dobPicker"
          width="290px"
          :return-value.sync="player.dob"
          :disabled="disable"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on"
              :label="$v.player.dob.hasOwnProperty('required') ? 'Birthdate*' : 'Birthdate'"
              readonly
              v-model="dobF"
              :error-messages="dobErrors"
              :clearable="!requiredFields.includes('dob') && !disable"
              persistent-hint
              :hint="`What is your birthdate?`"
              color="color3"
              :disabled="disable"
              :hide-details="disable"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="dobPickerPicker"
            color="color3"
            :header-color="darkColor"
            v-model="player.dob"
            @change="$refs.dobPicker.save(player.dob); $v.player.dob.$touch()"
            :disabled="disable"
          ></v-date-picker>
        </v-dialog>
      </v-col>
      <!-- Shirt Size -->
      <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4"  v-if="_fields.includes('shirtSize') || _fields.includes('*')">
        <v-select
          :items="['XS','S','M','L','XL','2X']"
          v-model="player.shirtSize"
          :label="$v.player.shirtSize.hasOwnProperty('required') ? 'Adult T-Shirt Size*' : 'Adult T-Shirt Size'"
          :clearable="!disable"
          color="color3"
          item-color="color3"
          @blur="$v.player.shirtSize.$touch()"
          :error-messages="errors.shirtSize"
          :hint="`What is your adult shirt size?`"
          persistent-hint
          :disabled="disable"
          :hide-details="disable"
        ></v-select>
      </v-col>
      <!-- Height -->
      <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4"  v-if="_fields.includes('height') || _fields.includes('*')">
        <v-select
          :items="heightOptions"
          v-model="player.height"
          :label="$v.player.height.hasOwnProperty('required') ? 'Height*' : 'Height'"
          :hint="`How tall are you?`"
          persistent-hint
          :clearable="!disable"
          color="color3"
          item-color="color3"
          @blur="$v.player.height.$touch()"
          :error-messages="errors.height"
          @focus="heightFocus"
          menu-props="auto"
          :disabled="disable"
          :hide-details="disable"
        ></v-select>
      </v-col>
      <!-- Facebook -->
      <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4" v-if="_fields.includes('facebook') || _fields.includes('social') || _fields.includes('*')">
        <v-text-field
          name="facebook"
          :label="$v.player.facebook.hasOwnProperty('required') ? `Facebook*` : `Facebook`"
          v-model="player.facebook"
          @blur="$v.player.facebook.$touch()"
          :error-messages="errors.facebook"
          color="color3"
          :hint="`What is your Facebook username?`"
          persistent-hint
          :disabled="disable"
          :hide-details="disable"
        ></v-text-field>
      </v-col>
      <!-- Instagram -->
      <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4" v-if="_fields.includes('instagram') || _fields.includes('social') || _fields.includes('*')">
        <v-text-field
          name="instagram"
          :label="$v.player.instagram.hasOwnProperty('required') ? `Instagram*` : `Instagram`"
          v-model="player.instagram"
          @blur="$v.player.instagram.$touch()"
          :error-messages="errors.instagram"
          color="color3"
          :hint="`What is your Instagram handle?`"
          persistent-hint
          :disabled="disable"
          :hide-details="disable"
        ></v-text-field>
      </v-col>
      <!-- Twitter -->
      <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4" v-if="_fields.includes('twitter') || _fields.includes('social') || _fields.includes('*')">
        <v-text-field
          name="twitter"
          :label="$v.player.twitter.hasOwnProperty('required') ? `Twitter*` : `Twitter`"
          v-model="player.twitter"
          @blur="$v.player.twitter.$touch()"
          :error-messages="errors.twitter"
          color="color3"
          :hint="`What is your Twitter handle?`"
          persistent-hint
          :disabled="disable"
          :hide-details="disable"
        ></v-text-field>
      </v-col>
      <!-- Snapchat -->
      <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4" v-if="_fields.includes('snapchat') || _fields.includes('social') || _fields.includes('*')">
        <v-text-field
          name="snapchat"
          :label="$v.player.snapchat.hasOwnProperty('required') ? `Snapchat*` : `Snapchat`"
          v-model="player.snapchat"
          @blur="$v.player.snapchat.$touch()"
          :error-messages="errors.snapchat"
          color="color3"
          :hint="`What is your Snapchat handle?`"
          persistent-hint
          :disabled="disable"
          :hide-details="disable"
        ></v-text-field>
      </v-col>
      <!-- TikTok -->
      <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4" v-if="_fields.includes('tiktok') || _fields.includes('social') || _fields.includes('*')">
        <v-text-field
          name="tiktok"
          :label="$v.player.tiktok.hasOwnProperty('required') ? `TikTok*` : `TikTok`"
          v-model="player.tiktok"
          @blur="$v.player.tiktok.$touch()"
          :error-messages="errors.tiktok"
          color="color3"
          :hint="`What is your TikTok URL?`"
          persistent-hint
          :disabled="disable"
          :hide-details="disable"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-expand-transition>
      <div v-if="admin || isJunior || (user && user.vbl)">
        <v-row dense>
          <!-- Club -->
          <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4"  v-if="_fields.includes('club') || _fields.includes('*')">
            <ClubAutocomplete
              v-if="!addClub"
              :label="$v.player.club.hasOwnProperty('required') ? 'Club*' : 'Club'"
              :disabled="disable"
              :readonly="bvcaRestrict"
              v-model="player.club"
              :error-messages="(!disable && bvcaRestrict) ? 'Please contact the BVCA to update your club' : errors.club"
              @blur="$v.player.club.$touch()"
              :allowAdd="true"
              @manual-click="(v) => {player.club = v; addClub = true}"
            ></ClubAutocomplete>

            <v-text-field
              v-if="addClub"
              name="club"
              :label="$v.player.club.hasOwnProperty('required') ? 'Club*' : 'Club'"
              v-model="player.club"
              :hint="`What club do you play for?`"
              persistent-hint
              @blur="$v.player.club.$touch()"
              :error-messages="(!disable && bvcaRestrict) ? 'Please contact the BVCA to update your club' : errors.club"
              color="color3"
              :disabled="disable"
              :readonly="bvcaRestrict"
              :hide-details="disable"
              autofocus
            >
            <template v-slot:append-outer>
              <v-btn text icon @click.stop="addClub = false">
                <v-icon>fas fa-search</v-icon>
              </v-btn>
            </template>
            </v-text-field>
          </v-col>
          <!-- clubDirectorName -->
          <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4"  v-if="_fields.includes('clubDirectorName') || _fields.includes('*')">
            <v-text-field
              name="clubDirectorName"
              :label="$v.player.clubDirectorName.hasOwnProperty('required') ? 'Club Director\'s Name*' : 'Club Director\'s Name'"
              v-model="player.clubDirectorName"
              :hint="`Who is your club director?`"
              persistent-hint
              @blur="$v.player.clubDirectorName.$touch()"
              :error-messages="errors.clubDirectorName"
              color="color3"
              :disabled="disable"
              :hide-details="disable"
            ></v-text-field>
          </v-col>
          <!-- clubDirectorEmail -->
          <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4"  v-if="_fields.includes('clubDirectorEmail') || _fields.includes('*')">
            <v-text-field
              name="clubDirectorEmail"
              :label="$v.player.clubDirectorEmail.hasOwnProperty('required') ? 'Club Director\'s Email*' : 'Club Director\'s Email'"
              v-model="player.clubDirectorEmail"
              :hint="`What is your club director's email?`"
              persistent-hint
              @blur="$v.player.clubDirectorEmail.$touch()"
              :rules="$v.player.clubDirectorEmail.hasOwnProperty('required') && $v.player.clubDirectorEmail.$dirty ? [
                () => $v.player.clubDirectorEmail.required || `Club director's email is required`,
                () => $v.player.clubDirectorEmail.email || 'A valid email is required'
              ] : []"
              color="color3"
              :disabled="disable"
              :hide-details="disable"
              :error-messages="errors.clubDirectorEmail"
            ></v-text-field>
          </v-col>
          <!-- GRAD YEAR -->
          <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4"  v-if="_fields.includes('gradyear') || _fields.includes('*')">
            <v-autocomplete
              :items="gradYears"
              :label="$v.player.gradYear.hasOwnProperty('required') ? 'Graduation Year*' : 'Graduation Year'"
              v-model="player.gradYear"
              @blur="$v.player.gradYear.$touch()"
              :error-messages="errors.gradYear"
              single-line
              :clearable="!disable"
              :hint="`When do you graduate high school?`"
              persistent-hint
              color="color3"
              item-color="color3"
              menu-props="auto"
              autocomplete="false"
              type="search"
              :disabled="disable"
              :hide-details="disable"
            ></v-autocomplete>
          </v-col>
          <!-- HS -->
          <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4"  v-if="_fields.includes('highSchool') || _fields.includes('*')">
            <v-text-field
              name="highSchool"
              :label="$v.player.highSchool.hasOwnProperty('required') ? `Current School*` : `Current School`"
              v-model="player.highSchool"
              @blur="$v.player.highSchool.$touch()"
              :error-messages="errors.highSchool"
              color="color3"
              :hint="`What school do you attend?`"
              persistent-hint
              :disabled="disable"
              :hide-details="disable"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- committed -->
        <committed-form-fields
          v-if="_fields.includes('committed') || _fields.includes('*')"
          :player="player"
          :disable="disable"
          :collegeList="collegeList"
          :errors="errors"
          :required="$v.player._committed.hasOwnProperty('required')"
        ></committed-form-fields>
      </div>
    </v-expand-transition>
    <v-row dense v-if="_fields.includes('facebook') || _fields.includes('instagram') || _fields.includes('twitter') || _fields.includes('snapchat') || _fields.includes('tiktok') || _fields.includes('social') || _fields.includes('*')">
    </v-row>
    <!-- Password -->
    <v-row dense v-if="!player.id && _fields.includes('password')">
      <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4">
        <v-text-field
          :label="$v.player.password.hasOwnProperty('required') ? 'Create a password*' : 'Create a password'"
          v-model="player.password"
          color="color3"
          @blur="$v.player.password.$touch()"
          :error-messages="errors.password"
          :disabled="disable"
          :hide-details="disable"
          :append-icon="hidePassword ? 'fal fa-eye' : 'fal fa-eye-slash'"
          @click:append="() => (hidePassword = !hidePassword)"
          :type="hidePassword ? 'password' : 'text'"
          autocomplete="new-password"
        ></v-text-field>
      </v-col>
      <v-col cols="12" :sm="embed ? 12 : 6" :md="embed ? 12 : 4">
        <v-text-field
          :label="$v.player.password.hasOwnProperty('required') ? 'Re-enter Password*' : 'Re-enter Password'"
          :append-icon="hidePassword2 ? 'fal fa-eye' : 'fal fa-eye-slash'"
          @click:append="() => (hidePassword2 = !hidePassword2)"
          :type="hidePassword2 ? 'password' : 'text'"
          autocomplete="new-password"
          v-model="repeatPassword"
          color="color3"
          @blur="repeatPasswordDirty = true"
          :error-messages="errors.repeatPassword"
          :disabled="disable"
          :hide-details="disable"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
import Heights from '@/classes/Heights'
import { mapGetters } from 'vuex'
import * as actions from '@/store/ActionTypes'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import ValidUSPhone from '@/helpers/ValidUSPhone'
import CommittedFormFields from './CommittedFormFields'
import ClubAutocomplete from '../Clubs/ClubAutocomplete.vue'

export default {
  directives: { mask },
  props: {
    player: { type: Object },
    embed: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    admin: { type: Boolean, default: false },
    fields: { type: Array, default: () => ['shirtSize', 'height', 'club', 'highSchool'] },
    requiredFields: {
      type: Array,
      default: function () {
        return ['phone', 'email', 'fullAddress', 'dob', 'gradyear', 'password']
      }
    },
    allowInternational: { type: Boolean, default: false },
    validate: { type: Boolean, default: false },
    noSetValid: { type: Boolean, default: false },
    onFile: { type: Boolean, default: false },
    solo: { type: Boolean, default: false },
    bvcaRestrict: { type: Boolean, default: false }
  },
  mixins: [validationMixin],
  validations () {
    return {
      player: {
        firstName: this._fields.includes('name') ? { required } : {},
        lastName: this._fields.includes('name') ? { required } : {},
        country: this.enforceV && this.allowInternational && (this.requiredFields.includes('fullAddress') || this.requiredFields.includes('cityState')) ? { required } : {},
        phone: this.enforceV && this.requiredFields.includes('phone') ? !this.international ? { required, ValidUSPhone } : { required } : {},
        email: this.enforceV && this.requiredFields.includes('email') ? { required, email } : {},
        address: this.enforceV && this.requiredFields.includes('fullAddress') ? { required } : {},
        city: this.enforceV && (this.requiredFields.includes('fullAddress') || this.requiredFields.includes('cityState')) ? { required } : {},
        state: this.enforceV && (this.requiredFields.includes('fullAddress') || this.requiredFields.includes('cityState')) ? { required } : {},
        zip: this.enforceV && (this.requiredFields.includes('fullAddress') || this.requiredFields.includes('zip')) && !this.international ? { required } : {},
        dob: this.enforceV && this.requiredFields.includes('dob') ? { required } : {},
        club: this.enforceV && this.requiredFields.includes('club') && this.isJunior ? { required } : {},
        gradYear: this.enforceV && this.requiredFields.includes('gradyear') && this.isJunior ? { required } : {},
        highSchool: this.enforceV && this.requiredFields.includes('highSchool') && this.isJunior ? { required } : {},
        aauNumber: this.enforceV && this.requiredFields.includes('aau') ? { required } : {},
        avpNumber: this.enforceV && this.requiredFields.includes('avp') ? { required } : {},
        shirtSize: this.enforceV && this.requiredFields.includes('shirtSize') ? { required } : {},
        height: this.enforceV && this.requiredFields.includes('height') ? { required } : {},
        clubDirectorName: this.enforceV && this.requiredFields.includes('clubDirectorName') ? { required } : {},
        clubDirectorEmail: this.enforceV && this.requiredFields.includes('clubDirectorEmail') ? { required, email } : {},
        sandRecruitsNumber: this.enforceV && this.requiredFields.includes('sandRecruitsNumber') ? { required } : {},
        _committed: this.enforceV && this.requiredFields.includes('committed') ? { required } : {},
        committedSchool: this.enforceV && this.player.committed ? { required } : {},
        password: this.enforceV && this.requiredFields.includes('password') && !this.player.id ? { required, minLength: minLength(6) } : {},
        facebook: this.enforceV && this.requiredFields.includes('facebook') ? { required } : {},
        instagram: this.enforceV && this.requiredFields.includes('instagram') ? { required } : {},
        twitter: this.enforceV && this.requiredFields.includes('twitter') ? { required } : {},
        snapchat: this.enforceV && this.requiredFields.includes('snapchat') ? { required } : {},
        tiktok: this.enforceV && this.requiredFields.includes('tiktok') ? { required } : {}
      }
    }
  },
  data () {
    return {
      loading: false,
      dobPicker: false,
      attendHS: false,
      repeatPassword: null,
      repeatPasswordDirty: false,
      hidePassword: true,
      hidePassword2: true,
      showJr: false,
      states: [],
      addClub: false
    }
  },
  computed: {
    ...mapGetters(['user', 'countries', 'collegeList', 'darkColor']),
    newPasswordErrors () {
      const errors = []
      if (!this.$v.player.password.$dirty) return errors
      !this.$v.player.password.required && errors.push('A password is required')
      !this.$v.player.password.minLength && errors.push('Your password must be at least 6 characters')
      !/\d/.test(this.player.password) && errors.push('Your password must have at least one digit (0-9).')
      return errors
    },
    errors () {
      return {
        // eslint-disable-next-line no-prototype-builtins
        firstName: this.enforceV && 'required' in this.$v.player.firstName && this.$v.player.firstName.$dirty && !this.$v.player.firstName.required ? ['A first name is required'] : [],
        lastName: this.enforceV && 'required' in this.$v.player.lastName && this.$v.player.lastName.$dirty && !this.$v.player.lastName.required ? ['A last name is required'] : [],
        country: this.enforceV && 'required' in this.$v.player.country && this.$v.player.country.$dirty && !this.$v.player.country.required ? ['A country is required'] : [],
        phone: this.enforceV && 'required' in this.$v.player.phone && this.$v.player.phone.$dirty && !(this.$v.player.phone.required && (this.$v.player.phone.ValidUSPhone || this.international)) ? ['A valid mobile phone is required'] : [],
        email: this.enforceV && 'required' in this.$v.player.email && this.$v.player.email.$dirty && !this.$v.player.email.required ? ['An email is required'] : [],
        address: this.enforceV && 'required' in this.$v.player.address && this.$v.player.address.$dirty && !this.$v.player.address.required ? ['An address is required'] : [],
        city: this.enforceV && 'required' in this.$v.player.city && this.$v.player.city.$dirty && !this.$v.player.city.required ? ['A city is required'] : [],
        state: this.enforceV && 'required' in this.$v.player.state && this.$v.player.state.$dirty && !this.$v.player.state.required ? ['A State/Province/Region is required'] : [],
        zip: this.enforceV && 'required' in this.$v.player.zip && this.$v.player.zip.$dirty && !this.$v.player.zip.required ? ['A zip is required'] : [],
        dob: this.enforceV && 'required' in this.$v.player.dob && this.$v.player.dob.$dirty && !this.$v.player.dob.required ? ['A dob is required'] : [],
        club: this.enforceV && 'required' in this.$v.player.club && this.$v.player.club.$dirty && !this.$v.player.club.required ? ['A club is required'] : [],
        gradYear: this.enforceV && 'required' in this.$v.player.gradYear && this.$v.player.gradYear.$dirty && !this.$v.player.gradYear.required ? ['A graduation year is required'] : [],
        highSchool: this.enforceV && 'required' in this.$v.player.highSchool && this.$v.player.highSchool.$dirty && !this.$v.player.highSchool.required ? ['A school is required'] : [],
        aauNumber: this.enforceV && 'required' in this.$v.player.aauNumber && this.$v.player.aauNumber.$dirty && !this.$v.player.aauNumber.required ? ['An AAU number is required'] : [],
        avpNumber: this.enforceV && 'required' in this.$v.player.avpNumber && this.$v.player.avpNumber.$dirty && !this.$v.player.avpNumber.required ? ['An AVP number is required'] : [],
        shirtSize: this.enforceV && 'required' in this.$v.player.shirtSize && this.$v.player.shirtSize.$dirty && !this.$v.player.shirtSize.required ? ['A shirtSize is required'] : [],
        height: this.enforceV && 'required' in this.$v.player.height && this.$v.player.height.$dirty && !this.$v.player.height.required ? ['A height is required'] : [],
        clubDirectorName: this.enforceV && 'required' in this.$v.player.clubDirectorName && this.$v.player.clubDirectorName.$dirty && !this.$v.player.clubDirectorName.required ? ['A club director name is required'] : [],
        clubDirectorEmail: this.enforceV && 'required' in this.$v.player.clubDirectorEmail && this.$v.player.clubDirectorEmail.$dirty && !this.$v.player.clubDirectorEmail.required ? ['A club director email is required'] : [],
        _committed: this.enforceV && 'required' in this.$v.player._committed && this.$v.player._committed.$dirty && !this.$v.player._committed.required ? ['Yes or No is required'] : [],
        committedSchool: this.enforceV && 'required' in this.$v.player.committedSchool && this.$v.player.committedSchool.$dirty && !this.$v.player.committedSchool.required ? ['A school is required'] : [],
        password: this.enforceV && 'required' in this.$v.player.password ? this.newPasswordErrors : [],
        repeatPassword: this.enforceV && 'required' in this.$v.player.password && this.repeatPasswordDirty && !this.repeatPasswordValid ? ['Your passwords do not match'] : [],
        facebook: this.enforceV && 'required' in this.$v.player.facebook && this.$v.player.facebook.$dirty && !this.$v.player.facebook.required ? ['A facebook is required'] : [],
        instagram: this.enforceV && 'required' in this.$v.player.instagram && this.$v.player.instagram.$dirty && !this.$v.player.instagram.required ? ['A instagram is required'] : [],
        twitter: this.enforceV && 'required' in this.$v.player.twitter && this.$v.player.twitter.$dirty && !this.$v.player.twitter.required ? ['A twitter is required'] : [],
        snapchat: this.enforceV && 'required' in this.$v.player.snapchat && this.$v.player.snapchat.$dirty && !this.$v.player.snapchat.required ? ['A snapchat is required'] : [],
        tiktok: this.enforceV && 'required' in this.$v.player.tiktok && this.$v.player.tiktok.$dirty && !this.$v.player.tiktok.required ? ['A tiktok is required'] : []
      }
    },
    userIsAdmin () {
      return this.user && this.user.vbl && false
    },
    enforceV () {
      return !this.admin && !this.userIsAdmin
    },
    repeatPasswordValid () {
      return !this.player.id && this.enforceV && this.requiredFields.includes('password') ? this.player.password === this.repeatPassword : true
    },
    international () {
      if (!this.allowInternational) return false
      return this.player && this.player.country && this.player.country !== 'United States'
    },
    dobErrors () {
      const errors = []
      if (this.requiredFields.includes('dob')) {
        if (!this.$v.player.dob.$dirty) return errors
        !this.$v.player.dob.required && errors.push('A birthdate is required')
      }
      return errors
    },
    age () {
      return this.player && this.player.dob ? moment().diff(moment(this.player.dob), 'years', true) : 0
    },
    isJunior () {
      return this.age && this.age <= 19
    },
    showJuniorFields () {
      if (this.admin || this.isJunior) return true
      return ((this._fields.includes('club') || this._fields.includes('*')) && this.player.club) ||
        ((this._fields.includes('gradyear') || this._fields.includes('*')) && this.player.gradYear) ||
        ((this._fields.includes('highSchool') || this._fields.includes('*')) && this.player.highSchool) ||
        ((this._fields.includes('committed') || this._fields.includes('*')) && this.player.committed)
    },
    validX () { return this.errorCount === 0 && this.repeatPasswordValid },
    valid () { return !this.$v.$invalid && this.repeatPasswordValid },
    _fields () {
      return this.fields.concat(this.requiredFields)
    },
    verified () {
      if (!this.sanctioningBody) return true
      if (this.sanctioningBody.toLowerCase() === 'aau') {
        return this.isOnFile('aauNumber') || this.aauIsValid || this.international
      } else if (this.sanctioningBody.toLowerCase() === 'avp') {
        return this.isOnFile('avpNumber') || this.avpIsValid
      }
      return true
    },
    gradYears () {
      const years = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
        return i + new Date().getFullYear()
      })

      if (this.player && this.player.gradYear && !years.includes(this.player.gradYear)) {
        years.unshift(this.player.gradYear)
      }

      return years
    },
    errorCount () {
      let i = 0
      for (const key in this.errors) {
        const element = this.errors[key]
        i += element.length
      }
      return i
    },
    heightOptions () {
      return Heights
    },
    dobF: {
      get () {
        return this.player.dob ? moment(this.player.dob.replace('Z', '')).format('MM/DD/YYYY') : ''
      },
      set (val) {
        this.player.dob = val
      }
    }
  },
  methods: {
    setValid () {
      if (!this.noSetValid) this.player.valid = this.valid
      this.$emit('valid-change', this.valid)
    },
    heightFocus () {
      if (!this.player.height) {
        this.player.height = '5\' 7"'
        setTimeout(() => {
          this.player.height = null
        }, 50)
      }
    },
    resetPlayer () {
      this.player.init()
    },
    getCountries () {
      this.loading = true
      this.$VBL.get.countries()
        .then(r => {
          this.countries = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    setShowJr () {
      this.showJr = this.showJuniorFields
    },
    getStates () {
      if (this.states.length) return
      this.loading = true
      this.$http.get(`${window.location.origin}/static/json/states.json`)
        .then(r => {
          this.states = r.data
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    valid: 'setValid',
    disable: function (val) {
      if (val) {
        this.$v.$reset()
      }
      this.setValid()
      this.setShowJr()
    },
    dobPicker: function (val) {
      val && this.$nextTick(() => (this.$refs.dobPickerPicker.activePicker = 'YEAR'))
      !val && this.$v.player.dob.$touch()
    },
    'player.key': function () {
      this.$v.$reset()
      this.setValid()
    },
    'player.committed': function (val) {
      if (!val) {
        this.player.committedSchool = null
        this.player.committedAs = null
      }
    },
    loading: function (val) {
      this.$emit('loading', val)
    },
    active: function (val) {
      if (val && !this.player.id) {
        this.$v.$reset()
        this.repeatPassword = null
        this.repeatPasswordDirty = false
      }
      this.setValid()
    },
    validate: function (val) {
      this.setValid()
      if (val) {
        this.$v.$touch()
        this.repeatPasswordDirty = true
      }
    }
  },
  components: {
    CommittedFormFields,
    ClubAutocomplete
  },
  mounted () {
    if (this._fields.includes('fullAddress') || this._fields.includes('cityState')) {
      this.getStates()
      this.$store.dispatch(actions.LOAD_COUNTRIES)
    }
    this.setShowJr()
    this._fields.includes('committed') && this.$store.dispatch(actions.LOAD_COLLEGE_LIST)
    this.setValid()
  }
}
</script>

<style scoped>
</style>
