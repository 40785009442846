<template>
  <v-data-table
    :headers="headers"
    :items="rows"
    :options.sync="pager"
    :loading="loading"
    loading-text="Loading the latest rankings..."
    :search="searchTerm"
    :footer-props="{itemsPerPageOptions: rowsPerPage}"
    :mobile-breakpoint="0"
    disable-sort
    class="og"
  >
    <template v-slot:progress>
      <v-progress-linear color="color3" indeterminate></v-progress-linear>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <v-row>
        <v-col class="shrink pa-0">
          <v-avatar
            size="46"
          >
            <img :src="item.profilePic || `https://vblimages.blob.core.windows.net/images/avatar-${item.isMale ? '' : 'fe'}male.jpg`" alt="~ :-)">
          </v-avatar>
        </v-col>
        <v-col class="grow pa-0 pl-1">
          <div class="font-weight-bold">{{item.name}} {{item.gradYear}} </div>
          <div class="font-weight-thin">{{item.cityState}}</div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:[`item.points`]="{ item }">
      {{item.points | fixed1}}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip left>
        <span>More on {{item.name}}</span>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="color3 color3Text--text" fab small @click.stop="gotoPlayer(item.playerProfileId)"><v-icon>fas fa-caret-right</v-icon></v-btn>
        </template>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
// import uniq from 'lodash.uniq'

export default {
  props: ['players', 'searchTerm', 'loading', 'sbid', 'filter', 'male', 'preCalc', 'filterTags'],
  data () {
    return {
      headers: [
        { text: 'Rank', value: 'rank', align: 'center', sortable: 'false', class: 'px-1' },
        { text: 'Player', value: 'name', align: 'left', sortable: 'false', class: 'px-1' },
        { text: 'Events', value: 'events', align: 'center', sortable: 'false', class: 'px-1' },
        { text: 'Points', value: 'points', align: 'center', sortable: 'false', class: 'px-1' },
        { text: '', value: 'actions', sortable: false, align: 'right', class: 'px-1' }
      ],
      pager: { sortDesc: false, itemsPerPage: 50 },
      rowsPerPage: [50, 100, 250, -1]
    }
  },
  computed: {
    rows () {
      if (!this.players || this.players.length === 0) return []

      let playerList = this.players.slice()

      if (this.filter && this.filter.length) {
        this.filterTags.forEach(filterArray => {
          const tagsSet = new Set(this.filter.filter(f => filterArray.includes(f)))

          if (tagsSet.size > 0) {
            playerList = playerList.filter((player) =>
              player.tags.some((playerTag) => tagsSet.has(playerTag))
            )
          }
        })
      }
      const sorted = playerList.sort((a, b) => b.points - a.points)

      let currentRank = 1
      let previousPoints = null
      let processed = 0 // how many players we've stepped through

      for (let i = 0; i < sorted.length; i++) {
        processed++

        // If this player's points differ from the previous player's,
        // the rank is the 1-based position in the sorted list
        if (sorted[i].points !== previousPoints) {
          currentRank = processed
          previousPoints = sorted[i].points
        }

        // Store rank on the object
        sorted[i].rank = currentRank
      }

      return playerList
    }
  },
  methods: {
    gotoPlayer (id) {
      this.$router.push({ name: 'player-profile', params: { playerProfileId: id, sbid: this.sbid } })
    }
  }
}
</script>

<style scoped>
.og,
.og >>> td {
  font-size: 0.8125rem !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
}
</style>
